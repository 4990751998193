import Session from "./Session";
import Utils from "horizon-js-front-sdk/lib/Utils";

export default class Family {
	public uid: string | null = null;
	public subscriptionUid: string | null = null;
	public templateUid: string | null = null;
	public name: string = "";
	public ownerUid?: string;
	public discountCodeId: string | null = null;

	get is_anonymous(): boolean {
		return this.ownerUid === "00000000-0000-0000-0000-000000000000";
	}

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Family.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				instance[prop] = json[prop];
			}
		}
	}

	exportToJson(): any {
		let JSONExport: any = {};
		JSONExport.uid = this.uid;
		JSONExport.subscriptionUid = this.subscriptionUid;
		JSONExport.templateUid = this.templateUid;
		JSONExport.name = this.name;
		if (this.ownerUid) {
			JSONExport.ownerUid = this.ownerUid;
		}
		if (this.discountCodeId !== "") {
			JSONExport.discountCodeId = this.discountCodeId;
		}
		return JSONExport;
	}

	isUserConnectedOwner(): boolean {
		let JWT: string | null = Session.getOauthToken();
		if (JWT) {
			return Utils.parseJwt(JWT).userId === this.ownerUid;
		}
		return false;
	}
}